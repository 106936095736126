<template>
  <div>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card>
          <div class="d-flex justify-content-start">
            <qrcode-vue :value="value" :size="size" level="H" />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="">Eduardo Kato de Araujo</h4>
                <div class="text-left mb-2">
                  <span class="card-text">CRECI: 43938</span>
                </div>
                <div class="text-center">
                  <img src="https://www.visaoalpha.com.br/img/logo2.e14c0a17.png" alt="" height="50px">
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  data() {
    return {
      value: "https://www.visaoalpha.com.br/cadastros/60f737501359fc76b34f2dd4",
      size: 150,
    };
  },
  components: {
    QrcodeVue,
  },
};
</script>